body {
  position: relative;
  min-height: 100vh;
  font-family: Montserrat, sans-serif;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  color: #fff;
  background-color: #05122b;
  background-repeat: no-repeat;
  background-image:
    radial-gradient(circle 50vw at 100% 0, #00fefe, transparent),
    radial-gradient(circle 80vw at 20% 25vw, #9b00cc, transparent),
    linear-gradient(180deg, #3f007e, #502cba 25vw, #05122b 50vw);
}
::-webkit-scrollbar {
  display: none;
}

h2 {
  font-size: 18px;
  padding: 0 5px;
}
a {
  text-decoration: none;
  color: #fff;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  margin: 0 auto 5vh;
}
img {
  width: 100%;
  height: auto;
}

.navbar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.logo {
  display: flex;
  align-items: center;
  img {
    width: 35px;
    height: 35px;
  }
  span {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 0 3px;
  }
}
.btn {
  font-size: 14px;
  width: 30px;
  height: 30px;
}
.mask {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  .menuList {
    width: 100%;
    height: 100%;
    background: #1a1b27;
    position: fixed;
    right: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow-y: scroll;

    // 返回+搜索
    .fnBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 20px;
      .back {
        width: 24px;
        height: 24px;
      }
    }
    .search {
      display: flex;
      align-items: center;
      position: relative;
      width: 85%;
      svg {
        position: absolute;
        left: 15px;
      }
      input {
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 45px;
        background: #373950;
        border-radius: 15px;
        border-radius: 30px;
        fill: #aaadbe;
        height: 34px;
        background-color: #373952;
        border-color: transparent;
        border-style: solid;
        border-width: 1px;
        box-shadow: 0px 0px;
        font-weight: 700;
        font-size: 14px;
        color: #f9faff;
        outline: 0;
      }
      .clear {
        width: 20px;
        position: absolute;
        right: 15px;
        font-size: 16px;
        color: #ddd;
        text-align: center;
      }
    }
    .searchResults {
      margin: 26px 0;
      a {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 10px;
        }
        span {
          margin: 0 10px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    p {
      color: #792af5;
      font-size: 14px;
      font-weight: 500;
    }
    .menu {
      display: flex;
      flex-direction: column;
      a {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 14px;
        margin: 0 0 12px;
        span {
          margin: 0 5px;
        }
      }
    }
    i {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.12);
      margin: 10px 0;
    }
  }
}

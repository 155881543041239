.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 40px 0;
  border-top: 1.6px solid;
  border-image: linear-gradient(
      46deg,
      #e71991 20%,
      #9ffe00 35%,
      #417efd 50%,
      #4a34b3 80%,
      #8c00ff 100%
    )
    1;
  text-align: center;
  font-size: 14px;
  > div {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5vh;
    opacity: 0.8;
  }
  span {
    font-size: 12px;
    opacity: 0.8;
  }
}
